"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyCodeComboDetector = void 0;
var EventDispatcher_1 = require("../../Modules/EventDispatcher");
var KeyCodeComboDetector = (function (_super) {
    __extends(KeyCodeComboDetector, _super);
    function KeyCodeComboDetector() {
        var _this = _super.call(this) || this;
        _this.nextComboIndex = 0;
        _this.register('konamicode');
        _this.initializeEventListeners();
        return _this;
    }
    KeyCodeComboDetector.prototype.initializeEventListeners = function () {
        var _this = this;
        document.addEventListener('keydown', function (event) {
            if (event.key === KeyCodeComboDetector.KonamiCombo[_this.nextComboIndex]) {
                ++_this.nextComboIndex;
                if (_this.nextComboIndex >= KeyCodeComboDetector.KonamiCombo.length) {
                    _this.nextComboIndex = 0;
                    _this.dispatch('konamicode');
                }
            }
            else {
                _this.nextComboIndex = 0;
            }
        });
    };
    KeyCodeComboDetector.KonamiCombo = [
        'ArrowUp',
        'ArrowUp',
        'ArrowDown',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'ArrowLeft',
        'ArrowRight',
        'b',
        'a',
        'Enter',
    ];
    return KeyCodeComboDetector;
}(EventDispatcher_1.Events.EventDispatcher));
exports.KeyCodeComboDetector = KeyCodeComboDetector;
