"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Projects = void 0;
exports.Projects = [
    {
        name: 'Battler',
        color: '#FF8946',
        image: 'battler.jpg',
        type: 'Rust Crate',
        date: 'Fall 2023 - Present',
        award: null,
        flavor: 'Pokémon battle engine for Rust.',
        repo: 'https://github.com/jackson-nestelroad/battler',
        external: null,
        details: [
            'Singles, doubles, and multi battles.',
            'Custom interpreted language, fxlang, for battle effects, expressed in JSON data.',
            'All moves, abilities, and items through Generation 3 implemented and validated.',
            'Team validation, Team Preview, custom rule sets.',
            'Volatiles, side conditions, entry hazards, weather, terrains, and more.',
            'Single player: level up, move learning, affection, disobedience, bag items, catching.',
        ],
    },
    {
        name: 'zone-alloc',
        color: '#00B086',
        image: 'zone-alloc.jpg',
        type: 'Rust Crate',
        date: 'Fall 2023',
        award: null,
        flavor: 'Rust crate for containers for zone-based data allocation.',
        repo: 'https://github.com/jackson-nestelroad/zone-alloc',
        external: 'https://crates.io/crates/zone_alloc',
        details: [
            '"Arena" for arena allocation for values of a given type.',
            '"Registry" for integer handles for allocated data.',
            '"StrongRegistry" for strongly-typed handles for allocated data.',
            '"KeyedRegistry" for mantaining a mapping of keys to allocated values.',
            'Runtime immutable and mutable borrow checking.',
        ],
    },
    {
        name: 'Christ-Centered 2.0',
        color: '#16BCA3',
        image: 'christ-centered-new.jpg',
        type: 'Browser Extension',
        date: 'Summer 2023',
        award: null,
        flavor: 'Browser extension to deliver the Bible Verse of the Day to your new tab.',
        repo: 'https://github.com/jackson-nestelroad/christ-centered-2.0',
        external: null,
        chrome: 'https://chrome.google.com/webstore/detail/christ-centered/adcoobhgdcnpbmkhajkabmkcfdekdibo',
        firefox: 'https://addons.mozilla.org/en-US/firefox/addon/christ-centered/',
        details: [
            'Implemented with React, TypeScript, and SCSS.',
            'Available on Chrome and Firefox.',
            'Displays verses, chapters, or entire books of the Bible in 10 different languages using DailyBread.',
            'Gives current weather for user\'s location via the OpenWeatherMap API.',
            'Persistent data synchronization between Redux and browser storage.'
        ],
    },
    {
        name: 'DailyBread',
        color: '#E87D1B',
        image: 'daily-bread.jpg',
        type: 'NPM Package',
        date: 'Winter 2023',
        award: null,
        flavor: 'TypeScript library for reading the Bible and verse of the day.',
        repo: 'https://github.com/jackson-nestelroad/daily-bread',
        external: 'https://www.npmjs.com/package/daily-bread',
        details: [
            'Implemented with TypeScript and Mocha.',
            'Usable in multiple JavaScript environments (Node.js or the browser).',
            'Reads one or more passages of the Bible using Bible Gateway.',
            'Customizable formatting, multi-version, and multi-lingual support.',
            'Command-line interface for reading passages, obtaining book information, and reading the verse of the day.',
        ],
    },
    {
        name: 'Wedding Seating Chart',
        color: '#393431',
        image: 'wedding-seating-chart.jpg',
        type: 'Side Project',
        date: 'Summer 2022',
        award: null,
        flavor: 'Simple web app for guests to receive a digital escort card.',
        repo: 'https://github.com/jackson-nestelroad/wedding-seating-chart',
        external: null,
        details: [
            'Implemented with React, TypeScript, and SCSS.',
            'Fully customizable wedding details and seating chart.',
            'Flexible searching by first name, last name, full name, or tag.',
        ],
    },
    {
        name: 'DataJoint Core',
        color: '#51A8B9',
        image: 'datajoint-core.jpg',
        type: 'Senior Capstone',
        date: 'Fall 2021',
        award: null,
        flavor: 'Rust library for shared code across user-level DataJoint frameworks.',
        repo: 'https://github.com/datajoint/datajoint-core',
        external: null,
        details: [
            'Implemented with Rust and SQLx.',
            'Moves database connection and querying code to a shared library to avoid code duplication.',
            'Allows connections to any MySQL or Postgres database.',
            'Exposed over a C FFI for user-level frameworks in Python, MATLAB, and more.',
        ],
    },
    {
        name: 'Panda',
        color: '#5865F2',
        image: 'panda-discord.jpg',
        type: 'NPM Package',
        date: 'Summer 2021',
        award: null,
        flavor: 'Command framework for building Discord bots with discord.js.',
        repo: 'https://github.com/jackson-nestelroad/panda-discord',
        external: 'https://www.npmjs.com/package/panda-discord',
        details: [
            'Implemented with TypeScript and discord.js.',
            'Strongly-typed, class-based structure for writing complex commands.',
            'Allows message commands and interaction commands to be handled by a single method.',
            'Handles argument parsing, categories, permissions, cooldowns, and more.'
        ],
    },
    {
        name: 'Spinda Discord Bot',
        color: '#F75D5D',
        image: 'spinda.jpg',
        type: 'Side Project',
        date: 'Fall 2020',
        award: null,
        flavor: 'Discord bot for generating Spinda patterns, custom commands, and more.',
        repo: 'https://github.com/jackson-nestelroad/spinda-discord-bot',
        external: null,
        details: [
            'Implemented with TypeScript, discord.js, Sequelize, and Panda.',
            'Deployed to Google Compute Engine, connected to a PostgreSQL server hosted on DigitalOcean.',
            "Generates a random pattern of the Pok\u00E9mon Spinda from 4,294,967,295 possibilities, matching the behavior of the mainline Pok\u00E9mon games",
            'Server-specific, highly-programmable custom commands.',
        ],
    },
    {
        name: 'Aether',
        color: '#1C1C67',
        image: 'aether.jpg',
        type: 'Side Project',
        date: 'Spring-Winter 2020',
        award: null,
        flavor: 'HTTP/HTTPS/WebSocket proxy server for viewing and intercepting web traffic.',
        repo: 'https://github.com/jackson-nestelroad/aether-proxy',
        external: null,
        details: [
            'Implemented with C++ using the Boost.Asio library.',
            'Multithreaded HTTP/HTTPS/WebSocket parsing, forwarding, and interception.',
            'Custom certificate authority and certificate generation with OpenSSL.',
            'WebSocket compression and decompression with zlib.',
            'Dozens of command-line options and event hook interceptors.',
        ]
    },
    {
        name: 'AR Sphere',
        color: '#DB4F54',
        image: 'ar-sphere.jpg',
        type: 'ACM Ignite',
        date: 'Fall 2019',
        award: 'First place for Fall 2019 ACM Ignite',
        flavor: 'Mobile application to place persistent AR models and experiences across the globe.',
        repo: 'https://github.com/jackson-nestelroad/ar-sphere-server',
        external: null,
        details: [
            'Semester-long team entrepreneurial project.',
            'Lead server developer with C#, ASP.NET Core MVC, and Entity Framework.',
            'Stream continuous data and real-time updates with SignalR.',
            'Saves geographical data with Azure Spatial Anchors.',
            'Deployed to Microsoft Azure with SQL Server and Blob Storage.'
        ]
    },
    {
        name: 'Portfolio Website',
        color: '#29AB87',
        image: 'portfolio-website.jpg',
        type: 'Side Project',
        date: 'Spring/Summer 2019',
        award: null,
        flavor: 'Personal website to showcase my work and experience.',
        repo: 'https://github.com/jackson-nestelroad/portfolio-website',
        external: 'https://jackson.nestelroad.com',
        details: [
            'Implemented from scratch with pure TypeScript.',
            'Custom-made, dynamic SCSS library.',
            'Class-based, easy-to-update JSX rendering for recurring content.',
            'Supports Internet Explorer 11.'
        ]
    },
    {
        name: 'Ponder',
        color: '#FFA500',
        image: 'ponder.jpg',
        type: 'Side Project',
        date: 'HackUTD 2019',
        award: '"Best UI/UX" for HackUTD 2019',
        flavor: 'Web and mobile application to make group brainstorming organized and efficient.',
        repo: 'https://github.com/jackson-nestelroad/ponder-hackutd-19',
        external: null,
        details: [
            'Implemented with React and Firebase Realtime Database.',
            'Complete connection and realtime updates with mobile counterpart.',
        ]
    },
    {
        name: 'Key Consumer',
        color: '#7A69AD',
        image: 'key-consumer.jpg',
        type: 'Side Project',
        date: 'January 2019',
        award: null,
        flavor: 'Windows command to attach a low-level keyboard hook in another running process.',
        repo: 'https://github.com/jackson-nestelroad/key-consumer',
        external: null,
        details: [
            'Implemented with C++ and Windows API.',
            'Attaches .dll file to another process to avoid detection.',
            'Intercepts and changes key inputs on the fly.'
        ]
    },
    {
        name: 'Comet Climate API',
        color: '#2D87C6',
        image: 'comet-climate.jpg',
        type: 'Class Project',
        date: 'November 2018',
        award: null,
        flavor: 'Self-updating API to collect current weather and Twitter data for the University of Texas at Dallas.',
        repo: 'https://github.com/jackson-nestelroad/comet-climate-server',
        external: null,
        details: [
            'Implemented with C# and the ASP.NET Core MVC.',
            'Deployed to Heroku with PostgreSQL database.',
            'Always returns data less than 10 minutes old.'
        ]
    },
    {
        name: 'Christ-Centered',
        color: '#FE904E',
        image: 'christ-centered.jpg',
        type: 'Side Project',
        date: 'Fall 2018',
        award: null,
        flavor: 'Google Chrome extension to deliver the YouVersion Verse of the Day to your new tab.',
        repo: 'https://github.com/jackson-nestelroad/christ-centered',
        external: 'http://bit.ly/christ-centered',
        details: [
            'Implemented with React and Chrome API.',
            'Custom verse searching by keyword or number.',
            'Gives current weather for user\'s location via the OpenWeatherMap API.'
        ]
    }
];
