"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Social = void 0;
exports.Social = [
    {
        name: 'GitHub',
        faClass: 'fab fa-github',
        link: 'https://github.com/jackson-nestelroad'
    },
    {
        name: 'LinkedIn',
        faClass: 'fab fa-linkedin',
        link: 'https://www.linkedin.com/in/jackson-nestelroad/'
    },
    {
        name: 'Email',
        faClass: 'fa-solid fa-envelope',
        link: 'mailto:jackson@nestelroad.com'
    }
];
