"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Stroke = (function () {
    function Stroke(width, color) {
        this.width = width;
        this.color = color;
    }
    return Stroke;
}());
exports.default = Stroke;
