"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElementFactory = void 0;
var ElementFactory;
(function (ElementFactory) {
    var Fragment = '<></>';
    function createElement(tagName, attributes) {
        var children = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            children[_i - 2] = arguments[_i];
        }
        if (tagName === Fragment) {
            return document.createDocumentFragment();
        }
        var element = document.createElement(tagName);
        if (attributes) {
            for (var _a = 0, _b = Object.entries(attributes); _a < _b.length; _a++) {
                var _c = _b[_a], key = _c[0], value = _c[1];
                if (key === 'className') {
                    element.setAttribute('class', value);
                }
                else if (key === 'style') {
                    if (typeof value === 'object') {
                        element.setAttribute('style', JStoCSS(value));
                    }
                    else {
                        element.setAttribute('style', value);
                    }
                }
                else if (key.startsWith('on') && typeof value === 'function') {
                    element.addEventListener(key.substring(2).toLowerCase(), value);
                }
                else {
                    if (typeof value === 'boolean' && value) {
                        element.setAttribute(key, '');
                    }
                    else {
                        element.setAttribute(key, value);
                    }
                }
            }
        }
        for (var _d = 0, children_1 = children; _d < children_1.length; _d++) {
            var child = children_1[_d];
            appendChild(element, child);
        }
        return element;
    }
    ElementFactory.createElement = createElement;
    function appendChild(parent, child) {
        if (typeof child === 'undefined' || child === null) {
            return;
        }
        if (Array.isArray(child)) {
            for (var _i = 0, child_1 = child; _i < child_1.length; _i++) {
                var value = child_1[_i];
                appendChild(parent, value);
            }
        }
        else if (typeof child === 'string') {
            parent.appendChild(document.createTextNode(child));
        }
        else if (child instanceof Node) {
            parent.appendChild(child);
        }
        else if (typeof child === 'boolean') {
        }
        else {
            parent.appendChild(document.createTextNode(String(child)));
        }
    }
    ElementFactory.appendChild = appendChild;
    function JStoCSS(cssObject) {
        var cssString = "";
        var rules = Object.keys(cssObject);
        for (var i = 0; i < rules.length; i++, cssString += ' ') {
            var rule = rules[i];
            cssString += rule.replace(/([A-Z])/g, function (upper) { return "-" + upper[0].toLowerCase(); }) + ": " + cssObject[rule] + ";";
        }
        return cssString;
    }
})(ElementFactory = exports.ElementFactory || (exports.ElementFactory = {}));
