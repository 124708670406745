"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Experience = void 0;
var Component_1 = require("../Component");
var JSX_1 = require("../../Definitions/JSX");
var Experience = (function (_super) {
    __extends(Experience, _super);
    function Experience() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Experience.prototype.beforeCreate = function () {
        if (this.data.collapse === undefined) {
            this.collapsible = false;
            this.collapsed = false;
        }
        else {
            this.collapsible = true;
            this.collapsed = !!this.data.collapse;
        }
    };
    Experience.prototype.setAccordionAttribute = function () {
        if (!this.collapsed) {
            this.getReference('accordion').setAttribute('open', '');
        }
        else {
            this.getReference('accordion').removeAttribute('open');
        }
    };
    Experience.prototype.created = function () {
        if (this.collapsible) {
            this.setAccordionAttribute();
        }
    };
    Experience.prototype.toggleAccordion = function () {
        this.collapsed = !this.collapsed;
        this.update();
    };
    Experience.prototype.update = function () {
        if (this.collapsible) {
            this.setAccordionAttribute();
            var panel = this.getReference('accordion').getElementsByClassName('panel').item(0);
            if (!(panel === null || panel === void 0 ? void 0 : panel.style)) {
                throw new Error('Failed to modify style of panel in accordion');
            }
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            }
            else {
                panel.style.maxHeight = panel.scrollHeight + 'px';
            }
        }
    };
    Experience.prototype.createCardBody = function () {
        return (JSX_1.ElementFactory.createElement("div", { className: "panel" },
            JSX_1.ElementFactory.createElement("hr", null),
            JSX_1.ElementFactory.createElement("div", { className: "content info" },
                JSX_1.ElementFactory.createElement("p", { className: "description is-size-8 is-color-light is-italic is-justified is-quote" }, this.data.flavor),
                JSX_1.ElementFactory.createElement("ul", { className: "job is-left-aligned is-size-7 xs-y-padding-between-1" }, this.data.roles.map(function (role) {
                    return JSX_1.ElementFactory.createElement("li", null, role);
                })))));
    };
    Experience.prototype.createIcon = function () {
        return JSX_1.ElementFactory.createElement("img", { src: "./images/Experience/" + this.data.svg + ".svg" });
    };
    Experience.prototype.createElement = function () {
        return (JSX_1.ElementFactory.createElement("div", { className: "card is-theme-secondary elevation-1 experience" },
            JSX_1.ElementFactory.createElement("div", { className: "content padding-2" },
                JSX_1.ElementFactory.createElement("div", { className: "header" },
                    JSX_1.ElementFactory.createElement("div", { className: "icon" }, this.data.disableLink
                        ? JSX_1.ElementFactory.createElement("div", null, this.createIcon())
                        : JSX_1.ElementFactory.createElement("a", { href: this.data.link, target: "_blank" }, this.createIcon())),
                    JSX_1.ElementFactory.createElement("div", { className: "company" },
                        JSX_1.ElementFactory.createElement("p", { className: "name is-size-6 is-bold-weight is-colored-link" }, this.data.disableLink
                            ? JSX_1.ElementFactory.createElement("div", null, this.data.company)
                            : JSX_1.ElementFactory.createElement("a", { href: this.data.link, target: "_blank" }, this.data.company)),
                        JSX_1.ElementFactory.createElement("p", { className: "team is-size-7 is-bold-weight is-color-light" }, this.data.team),
                        JSX_1.ElementFactory.createElement("p", { className: "location is-size-8 is-italic is-color-light" }, this.data.location)),
                    JSX_1.ElementFactory.createElement("div", { className: "role" },
                        JSX_1.ElementFactory.createElement("p", { className: "name is-size-7 is-bold-weight" }, this.data.position),
                        JSX_1.ElementFactory.createElement("p", { className: "date is-size-8 is-italic is-color-light" }, "(" + this.data.begin + " \u2014 " + this.data.end + ")"))),
                this.collapsible
                    ? JSX_1.ElementFactory.createElement("div", { className: "accordion", ref: "accordion" },
                        this.createCardBody(),
                        JSX_1.ElementFactory.createElement("div", { className: "toggle-wrapper is-color-light", onClick: this.toggleAccordion.bind(this) },
                            JSX_1.ElementFactory.createElement("button", { type: "button", className: "toggle btn is-svg is-primary is-center-sligned is-size-6" },
                                JSX_1.ElementFactory.createElement("i", { className: "fa-solid fa-chevron-down" }))))
                    : this.createCardBody())));
    };
    return Experience;
}(Component_1.DataComponent));
exports.Experience = Experience;
