"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Qualities = void 0;
exports.Qualities = [
    {
        faClass: 'fa-solid fa-gauge-high',
        name: 'Efficient',
        description: 'I consistently bring energy, productivity, organization, and agility to the table as an effective worker and a quick learner.'
    },
    {
        faClass: 'fa-solid fa-bug-slash',
        name: 'Attentive',
        description: 'To me, every detail matters. I love formulating the big picture just as much as measuring out the tiny details and edge cases.'
    },
    {
        faClass: 'fa-solid fa-shuffle',
        name: 'Versatile',
        description: 'I work best when I am challenged. While I thrive in organization, I can always adapt and pick up new things in a swift manner.'
    },
    {
        faClass: 'fa-solid fa-anchor',
        name: 'Consistent',
        description: 'I always aim to maintain high and dependable standards of quality. From interfaces to internals, excellence is an important priority.'
    },
    {
        faClass: 'fa-solid fa-handshake',
        name: 'Collaborative',
        description: 'I love working with people toward a shared vision, communicating ideas across teams, and encouraging and challenging others.'
    },
];
